// Generated by Framer (3c7efdd)

import * as React from "react";
import { motion, LayoutGroup } from "framer-motion";
import { useOnVariantChange, useActiveVariantCallback, Image, transformTemplate, addFonts, withCSS, addPropertyControls, ControlType, cx, useAddVariantProps, useVariantState, CycleVariantState, Text, Container, getFonts, useHydratedBreakpointVariants, removeHiddenBreakpointLayers, SSRVariants, GeneratedComponentContext } from "framer";
import { useRandomID } from "https://framer.com/m/framer/randomID.js@^2.0.0";
import TopNavigation from "../canvasComponent/FH9VG0Bot";
const TopNavigationFonts = getFonts(TopNavigation);

const cycleOrder = ["oXHoIcBXL"];

const breakpoints = {}

const isBrowser = typeof document !== "undefined";

const variantClassNames = {"oXHoIcBXL": "framer-v-eeurhf"};

if (isBrowser) {
                    removeHiddenBreakpointLayers("oXHoIcBXL", breakpoints, variantClassNames);
            }

const humanReadableVariantMap = {};

const transitions = {"default": {"duration": 0}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "oXHoIcBXL", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const [baseVariant, hydratedBaseVariant] = useHydratedBreakpointVariants(variant, breakpoints, false);
const gestureVariant = undefined;
const transition = transitions.default;

const variantProps = React.useMemo(() => ({}), []);

const addVariantProps = useAddVariantProps(hydratedBaseVariant, gestureVariant, variantProps);

const defaultLayoutId = useRandomID();

const { pointerEvents, ...style } = externalStyle

return (<GeneratedComponentContext.Provider value={{ primaryVariantId: "oXHoIcBXL", variantProps: variantProps, variantClassNames: variantClassNames}}>
<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated className={cx("framer-ww4u3")} style={{"display": "contents", "pointerEvents": pointerEvents ?? undefined}}>
<motion.div {...restProps} className={cx("framer-eeurhf", className)} style={{...style}} name="Desktop"  ref={ref} {...addVariantProps("oXHoIcBXL")}><Text withExternalLayout={true} verticalAlignment="top" __fromCanvasComponent={true}   fonts={["Inter-Bold"]}  className="framer-51xqo4" rawHTML={"<span style='font-size: 0; line-height: 0; tab-size: 4; white-space: inherit; word-wrap: inherit'><span style='direction: ltr; font-size: 0'><span style=''>Redesigning the Game Client</span><br></span></span>"} {...addVariantProps("Du8uL7AUJ")}/><Image className="framer-1i3z174"  name="lol_0"  background={{"src": new URL("assets/1024/NfYBUjhbO9tS8F3PWPBZuZqbW5Q.jpg", import.meta.url).href, "srcSet": `${new URL("assets/512/NfYBUjhbO9tS8F3PWPBZuZqbW5Q.jpg", import.meta.url).href} 512w, ${new URL("assets/1024/NfYBUjhbO9tS8F3PWPBZuZqbW5Q.jpg", import.meta.url).href} 1024w, ${new URL("assets/NfYBUjhbO9tS8F3PWPBZuZqbW5Q.jpg", import.meta.url).href} 1920w`, "sizes": "1200px", "pixelWidth": 1920, "pixelHeight": 960, "intrinsicWidth": 960, "intrinsicHeight": 480, "fit": "fill"}} data-framer-name="lol_0" alt="" {...addVariantProps("tw4YPJej_")}/><Container className="framer-1o1typi-container"  {...addVariantProps("fBPTdd39o-container")}><TopNavigation width="100%" height="100%" layoutId="fBPTdd39o" id="fBPTdd39o" style={{"width": "100%"}} {...addVariantProps("fBPTdd39o")}/></Container></motion.div>
</motion.div>
</LayoutGroup>
</GeneratedComponentContext.Provider>)

});

const css = [".framer-ww4u3 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none;}", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ww4u3 .framer-eeurhf { position: relative; overflow: auto; width: 1200px; height: 1080px; background-color: #ffffff; }", ".framer-ww4u3 .framer-51xqo4 { position: absolute; width: auto; height: auto; left: 228px; top: 720px; flex: none; white-space: pre; --framer-font-family: \"Inter-Bold\", \"Inter\", sans-serif; --framer-font-style: normal; --framer-font-weight: 700; --framer-text-color: #000000; --framer-font-size: 24px; --framer-letter-spacing: 0px; --framer-text-transform: none; --framer-text-decoration: none; --framer-line-height: 1.2em; --framer-text-alignment: start; --framer-link-text-color: #0099ff; --framer-link-text-decoration: underline; }", ".framer-ww4u3 .framer-1i3z174 { position: absolute; overflow: visible; max-width: 1200px; width: 1200px; height: var(--framer-aspect-ratio-supported, 600px); left: calc(50.00000000000002% - 1200px/2); top: 80px; flex: none; aspect-ratio: 2 / 1; }", ".framer-ww4u3 .framer-1o1typi-container { position: fixed; z-index: 1; width: 100%; height: auto; left: 0px; top: 0px; flex: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 1080
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName": "variant", "data": {"default": {"layout": ["fixed", "fixed"]}}}
 * @framerResponsiveScreen
 */
const FramerPYJBqJaQb: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerPYJBqJaQb;

FramerPYJBqJaQb.displayName = "Lol Client";

FramerPYJBqJaQb.defaultProps = {"width": 1200, "height": 1080};

addFonts(FramerPYJBqJaQb, [...TopNavigationFonts]);